import {
    COURSE_BADGE_INCLUDED_IN_PLANS,
    COURSE_BADGE_PREMIUM,
    COURSE_BADGE_TOP_PICK,
    COURSE_IN_SUBS_PLAN_BADGE_FAMILIES,
    CourseBadgeFamily,
} from '@udemy/browse-course';

export const COURSE_CARD_PREFETCH_FEATURE_CODE = 'course_card_prefetch';
export const SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE =
    'subscription_catalog_differentiator_feature';
export const DELAY_COURSE_CARD_QUICK_VIEW_BOX = 'delay_course_card_quick_view_box';
export const HIDE_WISHLIST_BUTTON_ON_COURSE_CARD_QUICK_VIEW_BOX =
    'hide_wishlist_button_on_course_card_quick_view_box';
export const COURSE_CARDS_OPEN_IN_NEW_TAB = 'course_cards_open_in_new_tab';
export const POPULAR_CATEGORIES_MODULE = 'popular_categories_module';
export const COURSE_GUIDANCE_FEATURE = 'course_guidance_feature';
export const LEARNERS_ARE_VIEWING_ON_LOHP = 'learners_are_viewing_on_lohp';
export const BROWSE_AGGREGATOR_PAGE_FEATURE = 'browse_aggregator_page';
export const SHOW_TRENDING_SKILLS_ABOVE_PARTNERS_BANNER =
    'show_trending_skills_above_partners_banner';
export const DESKTOP_POST_ADD_TO_CART_BEHAVIOR = 'desktop_post_add_to_cart_behavior';
export const NEW_SEARCH_BAR_MX = 'new_search_bar_mx';

// TODO: Clean up after subs catalog diff experiment is over https://udemyjira.atlassian.net/browse/CVR-151
export const getBrowseCourseBadgesContextOptionsByExperiment = (experiment?: {
    showTopPickBadge: boolean;
    showIncludedInPlansBadge: boolean;
    showPremiumBadge: boolean;
}) => {
    let badgeFamily: CourseBadgeFamily | undefined;
    if (experiment) {
        if (experiment.showTopPickBadge) {
            badgeFamily = COURSE_BADGE_TOP_PICK;
        } else if (experiment.showIncludedInPlansBadge) {
            badgeFamily = COURSE_BADGE_INCLUDED_IN_PLANS;
        } else if (experiment.showPremiumBadge) {
            badgeFamily = COURSE_BADGE_PREMIUM;
        }
    }
    return getBrowseCourseBadgesContextOptions(badgeFamily);
};

// TODO: Clean up after subs catalog diff experiment is over https://udemyjira.atlassian.net/browse/CVR-151
const getBrowseCourseBadgesContextOptions = (experimentVariant?: CourseBadgeFamily) => {
    // Make sure that we don't show the badge that is being tested
    if (!experimentVariant) {
        return {};
    }

    const excludedBadges = COURSE_IN_SUBS_PLAN_BADGE_FAMILIES.filter(
        (badgeFamily) => badgeFamily !== experimentVariant,
    );

    return {
        numberOfBadgesToShow: 2,
        filter: (badge: CourseBadgeFamily) => !excludedBadges.includes(badge),
        priority: {[experimentVariant]: 1},
    };
};
